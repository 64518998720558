

import {IonContent, IonHeader, IonInput, IonItem, IonPage, IonToolbar} from "@ionic/vue";
import {computed, onBeforeMount, ref} from "vue";
import FixedFooter from '@/components/FixedFooter.vue'
import {presentValidationAlert} from "@/services/Notify";
import { execute } from "@/mixins/LoadingMixin";
import {useRouter} from "vue-router";
import {
    resetPassword,
    isTokenValid as checkIfTokenValid,
    activateUser,
    isActivationValid,
    updatePassword as setPassword
} from "@/services/UserService";
import {useStore} from "vuex";

export default {
    name: 'ChangePassword',
    components: {
        IonContent,
        IonPage,
        IonItem,
        IonInput,
        IonHeader,
        IonToolbar,
        FixedFooter,
    },
    props: {
        token: {
            type: String,
            default: ''
        }
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const languagePreloaded = computed(() => Object.keys(language.value).length > 0);
        const router = useRouter();
        const contentLoaded = ref(false);
        const password = ref('');
        const confirmPassword = ref('');
        const isTokenValid = ref(false);
        const footerButtons = ref([] as any);
        const changingCurrentPassword = computed(() => router.currentRoute.value.name === 'changeCurrentPassword');
        const resettingPassword = computed(() => router.currentRoute.value.name === 'resetPassword');
        
        const validate = async (): Promise<boolean> => {
            let message = '';
            if (password.value !== confirmPassword.value) {
                message = languagePreloaded.value ? language.value.validation.passwordsMustMatch : 'Passwords must match.';
            } else if (password.value.length < 8) {
                message = languagePreloaded .value? language.value.validation.passwordCharacters : 'Password must be at least 8 characters.';
            } else if (!/\d/.test(password.value)) {
                message = languagePreloaded.value ? language.value.validation.passwordNumbers : 'Password must contain at least one number.';
            } else if (!/(?=.*[A-Z])/.test(password.value)) {
                message = languagePreloaded.value ? language.value.validation.passwordUppercase : 'Password must contain at least one uppercase character.';
            } else if (!/(?=.*[a-z])/.test(password.value)) {
                message = languagePreloaded.value ? language.value.validation.passwordLowercase : 'Password must contain at least one lowercase character.';
            } else if (!/(?=.*[@#$%^!&+=_.-])/.test(password.value)) {
                message = languagePreloaded.value ? language.value.validation.passwordSpecial : 'Password must contain at least one of the specified special characters.';
            }
            
            if (message) {
                await presentValidationAlert(message);
                return false;
            }
            return true;
        }

        const updatePassword = async () => {
            const errorMsg = languagePreloaded.value ? language.value.errors.settingNewPassword : 'Error setting new password.';
            await execute(async () => {
                const valid = await validate();
                let message = '';
                if (valid) {
                    if (changingCurrentPassword.value) {
                        await setPassword(password.value);
                        router.back();
                    }
                    else {
                        if (resettingPassword.value) {
                            await resetPassword(props.token, password.value);
                            message = languagePreloaded.value ? language.value.passwordWasReset : 'Password reset.';
                        } else {
                            await activateUser(props.token, password.value);
                            message = languagePreloaded.value ? language.value.beenActivated : 'You have been activated. You may now log in.';
                        }

                        if (message) {
                            await presentValidationAlert(message);
                            await router.replace({name: 'login'});
                        } else {
                            await presentValidationAlert(errorMsg);
                        }
                    }
                }
            }, errorMsg);
        }

        onBeforeMount(async () => {
            if (changingCurrentPassword.value) {
                isTokenValid.value = true;
                await store.dispatch('app/setPageTitle', languagePreloaded.value ? language.value.passwordUpdate : 'Password Update');
            } else if (resettingPassword.value) {
                await execute(async () => {
                    isTokenValid.value = await checkIfTokenValid(props.token);
                }, languagePreloaded.value ? language.value.errors.gettingPasswordResetStatus : 'Error getting password reset status.');
            } else {
                await execute(async () => {
                    isTokenValid.value = await isActivationValid(props.token);
                }, languagePreloaded.value ? language.value.errors.gettingActivationStatus : 'Error getting activation status.');
            }
            
            footerButtons.value.push({
                class: 'custom-button button-clear',
                icon: false,
                iconPos: '',
                handler: () => changingCurrentPassword.value ? router.back() : router.replace({ name: 'login'}),
                text: languagePreloaded.value ? language.value.cancel : 'Cancel'
            });
            if (isTokenValid.value) {
                footerButtons.value.push({
                    class: 'custom-button button-primary',
                    icon: false,
                    iconPos: '',
                    handler: () => updatePassword(),
                    text: changingCurrentPassword.value ? (languagePreloaded.value ? language.value.update : 'Update' ) : (languagePreloaded.value ? language.value.reset : 'Reset')
                });
            }
            contentLoaded.value = true;
        });
        
        return {
            footerButtons,
            password,
            confirmPassword,
            isTokenValid,
            language,
            languagePreloaded,
            contentLoaded
        }
    }
}
